import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { supabaseClient } from "../config/supabase";

const validationSchema = Yup.object({
  nombre: Yup.string().required("Nombre es obligatorio"),
  correo: Yup.string()
    .email("Correo inválido")
    .required("Correo es obligatorio"),
  organizacion: Yup.string().required("Organización es obligatoria"),
  proyecto: Yup.string().required("Cuéntanos sobre tu proyecto"),
});

const submitForm = async (values, { resetForm }) => {
  try {
    const { error } = await supabaseClient.from("contact_form").insert([
      {
        nombre: values.nombre,
        correo: values.correo,
        organizacion: values.organizacion,
        proyecto: values.proyecto,
      },
    ]);

    if (error) {
      if (
        error.message ===
        'duplicate key value violates unique constraint "unique_correo"'
      ) {
        alert(
          "No pudimos guardar tu información. Ya tenemos un registro con ese correo. "
        );
      }
    } else {
      alert("Se envío de manera correcta tu información");
      resetForm();
    }
  } catch (err) {
    alert("Sucedió un error inesperado.");
  }
};

const Contacto = () => {
  return (
    <motion.div
      initial={{ x: "100%", opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: "100%", opacity: 0 }}
      transition={{ duration: 1.2, ease: "easeInOut", type: "spring" }}
      className="min-h-screen bg-orange-600 p-8 text-black flex flex-col md:flex-row"
    >
      <div className="basis-3/4 px-4 py-2">
        <h2 className="text-orangeText26N font-druk-wide font-bold uppercase text-3xl md:text-h2-desktop  w-full ">
          Queremos contar tu historia
        </h2>

        <Formik
          initialValues={{
            nombre: "",
            correo: "",
            organizacion: "",
            proyecto: "",
          }}
          validationSchema={validationSchema}
          onSubmit={submitForm}
        >
          {({ isSubmitting }) => (
            <Form className="space-y-6">
              <div>
                {/* <h5 className="text-h5-desktop text-white font-ibm-plex mt-2 transition-opacity duration-300">
              Las épocas cambian, las tendencias pasan, pero las historias
              permanecen.
            </h5> */}
                <Field
                  name="nombre"
                  placeholder="Nombre"
                  className="placeholder-orangeText26N text-orangeText26N text-h5-desktop font-ibm-plex  w-full p-2 border-b border-black bg-transparent focus:outline-none"
                />
                <ErrorMessage
                  name="nombre"
                  component="div"
                  className="text-error26N"
                />
              </div>

              <div>
                <Field
                  name="correo"
                  type="email"
                  placeholder="Correo electrónico"
                  className="placeholder-orangeText26N text-orangeText26N text-h5-desktop font-ibm-plex  w-full p-2 border-b border-black bg-transparent focus:outline-none"
                />
                <ErrorMessage
                  name="correo"
                  component="div"
                  className="text-error26N"
                />
              </div>

              <div>
                <Field
                  name="organizacion"
                  placeholder="Organización"
                  className="placeholder-orangeText26N text-orangeText26N text-h5-desktop font-ibm-plex  w-full p-2 border-b border-black bg-transparent focus:outline-none"
                />
                <ErrorMessage
                  name="organizacion"
                  component="div"
                  className="text-error26N"
                />
              </div>

              <div>
                <Field
                  as="textarea"
                  name="proyecto"
                  placeholder="Cuéntanos sobre tu proyecto"
                  className="placeholder-orangeText26N text-orangeText26N text-h5-desktop font-ibm-plex  w-full p-2 border-b border-black bg-transparent focus:outline-none"
                />
                <ErrorMessage
                  name="proyecto"
                  component="div"
                  className="text-error26N"
                />
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="border border-black px-6 py-2 rounded-full hover:bg-orangeText26N hover:text-white hover:border-orangeText26N"
                >
                  Enviar
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="basis-1/4 flex flex-col justify-between px-12">
        <Link
          to="/"
          className="self-end w-auto border border-black text-black px-4 py-2 rounded-full hover:bg-orangeText26N hover:text-white hover:border-orangeText26N hidden md:block"
        >
          VOLVER AL HOME
        </Link>
        <div className="mt-5">
          <h5 className="text-h5-desktop text-black font-ibm-plex mt-2 transition-opacity duration-300">
            CONTACTO
          </h5>
          <a
            href="mailto:atencion@26norte.mx"
            className="font-ibm-plex text-black text-p-desktop leading-tight"
          >
            atencion@26norte.mx
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default Contacto;
